/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createRef, PureComponent, RefObject } from 'react';
import { BackendProp, FontSize, Size } from '../../../types/editor';
import { WorkspaceContext, WorkspaceProviderContext } from '../../../providers/WorkspaceProvider';

export type EditorNodePosition = {
    x: number;
    y: number;
};

export type EditorNodeBounds = {
    top: number;
    left: number;
    right: number;
    bottom: number;
};

export type EditorNodeTextMetadata = {
    kind: 'text';
    position: EditorNodePosition;
    size: Size;
    content: { text: string; fontSize: FontSize; bold?: boolean };
};

export type EditorNodeImageMetadata = {
    kind: 'image';
    position: EditorNodePosition;
    size: Size;
    content: { blob: string };
};

export type EditorNodeBackendPropMetadata = {
    kind: 'backendProp';
    position: EditorNodePosition;
    size: Size;
    content: { prop: BackendProp; fontSize: FontSize; bold?: boolean };
};

export type EditorNodeMetadata =
    | EditorNodeTextMetadata
    | EditorNodeImageMetadata
    | EditorNodeBackendPropMetadata;

export type OnDelete = () => void;
export type DehydrateBindings = (onDelete: OnDelete) => JSX.Element;

export type NodeProps = {
    size: Size;
    position: EditorNodePosition;
    onDelete: OnDelete;
};

type NodeSettings = {
    selected: boolean;
    dragging: boolean;
    editable: boolean;
    draggable: boolean;
    resizable: boolean;
    size: Size;
    position: EditorNodePosition;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export abstract class AbstractEditorNode<P = {}, S = {}> extends PureComponent<
    P & NodeProps,
    S & NodeSettings
> {
    static contextType = WorkspaceContext;

    context!: WorkspaceProviderContext;

    blockRef = createRef<HTMLDivElement>();

    inputRef = createRef<HTMLInputElement>();

    textAreaRef = createRef<HTMLTextAreaElement>();

    abstract onEditHandler(): void;

    abstract onSaveHandler(): void;

    abstract onDeleteHandler(): void;

    abstract onChangeHandler(e: unknown): void;

    // eslint-disable-next-line react/sort-comp
    abstract hydrate(): EditorNodeMetadata;

    getBlockSize = (): Size => ({
        width: this.blockRef.current?.clientWidth || 0,
        height: this.blockRef.current?.clientHeight || 0,
    });

    getCanvasBounds = (): EditorNodeBounds => {
        const { padding, size } = this.context;
        const blockSize = this.getBlockSize();
        return {
            top: padding,
            left: padding,
            right: size.width - blockSize.width,
            bottom: size.height - blockSize.height,
        };
    };

    toggleSelected = (): void => {
        const { resizable, editable, dragging, selected } = this.state;

        const selectedState = dragging || resizable || editable ? true : !selected;

        this.setState((state) => ({
            ...state,
            selected: selectedState,
            dragging: false,
            resizable: false,
        }));
    };

    clickAwayHandler = (): void =>
        this.setState((state) => ({
            ...state,
            selected: false,
            resizable: false,
            editable: false,
            dragging: false,
            draggable: false,
        }));

    onResizeHandler = (): void => {
        this.setState(
            (state) => ({ ...state, editable: false, resizable: true }),
            () => this.setState((state) => ({ ...state, selected: true, resizable: true }))
        );
    };

    mouseDownHandler: React.MouseEventHandler = () => {
        const { selected, dragging, editable } = this.state;

        if (editable) return;

        const canvasBounds = this.getCanvasBounds();

        const moveThreshold = 1;

        const moveHandler = (e: MouseEvent) => {
            const { position } = this.state;

            let x = position.x + e.movementX; // TODO: replace with clientXY
            let y = position.y + e.movementY; // TODO: replace with clientXY

            const moveDeltaX = Math.abs(x - position.x);
            const moveDeltaY = Math.abs(x - position.y);

            if (!dragging && (moveDeltaX >= moveThreshold || moveDeltaY >= moveThreshold)) {
                this.setState((state) => ({ ...state, dragging: true }));
            }

            if (x <= canvasBounds.left) x = canvasBounds.left;
            else if (x >= canvasBounds.right) x = canvasBounds.right;

            if (y <= canvasBounds.top) y = canvasBounds.top;
            else if (y >= canvasBounds.bottom) y = canvasBounds.bottom;

            this.setState((state) => ({ ...state, position: { x, y } }));
        };

        const endHandler = () => window.removeEventListener('mousemove', moveHandler);

        if (selected) window.addEventListener('mousemove', moveHandler);
        window.addEventListener('mouseup', endHandler, { once: true });
    };

    resizeMouseDownHandler: React.MouseEventHandler = (evt) => {
        const { size: canvasSize, padding } = this.context;
        const { selected, size } = this.state;

        const maxWidth = canvasSize.width - padding;
        const maxHeight = canvasSize.height - padding;

        const startX = evt.clientX;
        const startY = evt.clientY;

        const moveHandler = (e: MouseEvent) => {
            let width = size.width - (startX - e.clientX);
            let height = size.height - (startY - e.clientY);

            if (width < 10) width = 10;
            else if (width > maxWidth) width = maxWidth;

            if (height < 10) height = 10;
            else if (height > maxHeight) height = maxHeight;

            this.setState((state) => ({ ...state, size: { width, height } }));
        };

        const endHandler = () => {
            window.removeEventListener('mousemove', moveHandler);
            this.setState((state) => ({ ...state, resizable: false }));
        };

        if (selected) window.addEventListener('mousemove', moveHandler);
        window.addEventListener('mouseup', endHandler, { once: true });
    };
}

export interface EditorNodeInterface {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new (props: any): AbstractEditorNode<any, any>;

    dehydrate(meta: EditorNodeMetadata, selfRef: RefObject<AbstractEditorNode>): DehydrateBindings;
}
